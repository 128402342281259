export class Word {
  word: string;
  createdAtUtc: Date;
  context: string;
  additionalContext: string;

  constructor(data: any) {
    const dataCloned = { ...data } || {};
    this.word = dataCloned.word;
    this.createdAtUtc = dataCloned.createdAtUtc;
    this.context = dataCloned.context;
    this.additionalContext = dataCloned.additionalContext;
  }
}
