import { Box, Modal, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
export default function Loader(props?: {
  children: any;
  fullScreen?: boolean;
}) {
  const theme = useTheme();
  const addAlpha = (hex: string) => {
    // #RRGGBB
    let red = hex.substring(1, 3);
    let green = hex.substring(3, 5);
    let blue = hex.substring(5);
    return `rgba(${red}, ${green}, ${blue}, 0.6)`;
  };
  return props?.fullScreen ? (
    <Modal
      open={true}
      disableEnforceFocus
      disableAutoFocus
      disableEscapeKeyDown
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Modal>
  ) : (
    <div className="relative">
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          zIndex: 9,
          backgroundColor: addAlpha(theme.palette.background.default),
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <CircularProgress aria-selected={false} color="primary" />
      </Box>
      {props?.children}
    </div>
  );
}
