import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import {
  Box,
  Button,
  Modal,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { NewWord } from "../../common/models/NewWord";
import "./NewWordModal.css";

type NewWordModalProps = {
  open: boolean;
  close: () => void;
  save: (word: NewWord) => void;
};

export default function NewWordModal(props: NewWordModalProps) {
  const { formState, getFieldState, handleSubmit, register } = useForm<NewWord>(
    {
      defaultValues: {
        word: "",
        context: "",
        additionalContext: "",
      },
    },
  );

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  };

  const actionGroupStyle = {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    justifyContent: "end",
  };

  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };

  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );

  return (
    <Modal
      open={props.open}
      onClose={props.close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit(props.save)}>
        <Box sx={modalStyle}>
          <TextField
            placeholder="Word"
            {...register("word", { required: "Word is required" })}
          />
          <Textarea
            maxRows={4}
            minRows={4}
            placeholder="Context"
            {...register("context")}
          />
          <Textarea
            minRows={2}
            maxRows={4}
            placeholder="Additional Context"
            {...register("additionalContext")}
          />
          <Box sx={actionGroupStyle}>
            <Button
              // disabled={!formState.isDirty || !formState.isValid}
              variant="contained"
              type="submit"
            >
              Save
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={() => {
                props.close();
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </form>
    </Modal>
  );
}
