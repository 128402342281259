import React, { createContext } from "react";

export type LoginStateModel = {
  isLogin: boolean;
  userName: string;
  profilePicture: string;
};

export type LoginContextModel = {
  state: LoginStateModel,
  setState: React.Dispatch<React.SetStateAction<LoginStateModel>>
}

export const LoginContext = createContext<LoginContextModel>({
  state: {
    isLogin: false,
    userName: "",
    profilePicture: "",
  },
  setState: () => { }
});
