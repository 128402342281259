import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Box, Modal, Typography } from "@mui/material";
import { computeHashCode } from '../../common/helpers/compute-hash';
import {
  WordClass,
  WordClassDefinition,
  WordContext,
  WordDefinition,
  WordPronounce,
} from "../../common/models/WordDefinition";
import "./WordDefinitionModal.css";

type WordDefinitionModalProps = {
  open: boolean;
  handleClose: () => void,
  wordDefinition: WordDefinition;
};

export default function WordDefinitionModal(props: WordDefinitionModalProps) {
  let definition = props.wordDefinition;
  const classKeyGenerator = (className: string, index: number) => `${className}_${index}`;
  const pronounceKeyGenerator = (classNameKey: string, pronounceRegion: string) => `${classNameKey}_${pronounceRegion}`;
  const wordClassDefinitionKey = (wordClassKey: string, definitionKey: string) => `${wordClassKey}_${definitionKey}`;
  const definitionContextKey = (definitionKey: string, context: string) => `${definitionKey}_${context}`;

  const speak = (audioIdTag: string) => {
    (document.getElementById(audioIdTag) as HTMLAudioElement).play();
  }

  const renderClass = (wordClass: WordClass, index: number) => {
    return (
      <div className="word-definition-class mb-16" key={classKeyGenerator(wordClass.className, index)}>
        <Typography variant="h6">{wordClass.className}</Typography>
        {wordClass.pronounces.map((pro) => renderPronounce(pro, classKeyGenerator(wordClass.className, index)))}
        {wordClass.definitions.map((def) => renderDefinition(def, classKeyGenerator(wordClass.className, index)))}
      </div>
    );
  };

  let renderPronounce = (pronounce: WordPronounce, classNameKey: string) => {
    return (
      <div className="inline mr-5" key={pronounceKeyGenerator(classNameKey, pronounce.region)}>
        <span className="mr-2 text-orange-400 font-bold">{pronounce.region}</span>
        <span className="mr-2 cursor-pointer align-bottom hover:text-orange-400" onClick={() => speak(pronounceKeyGenerator(classNameKey, pronounce.region))}><VolumeUpIcon titleAccess='speak' /></span>
        <audio id={pronounceKeyGenerator(classNameKey, pronounce.region)}>
          <source src={`https://dictionary.cambridge.org/${pronounce.link}`} type="audio/mpeg"></source>
        </audio>
        <span className="font-bold">{pronounce.ipa}</span>
      </div>
    );
  };

  const renderDefinition = (definition: WordClassDefinition, classNameKey: string) => {
    return (<div key={wordClassDefinitionKey(classNameKey, computeHashCode(definition.contexts.length.toString()))}>
      {definition.contexts.map((context) => renderDefinitionContext(context, wordClassDefinitionKey(classNameKey, computeHashCode(definition.contexts.length.toString()))))}
    </div>);
  }

  const renderDefinitionContext = (context: WordContext, definitionKey: string) => {
    return (<div key={definitionContextKey(definitionKey, context.description)}>
      <p className="text-orange-400">{context.description}</p>
      {context.meanings.map(x =>
        <div>
          <p className="text-green-500">
            {x.explanation}
          </p>
          {x.examples.map(ex => <p className='text-white'>{ex}</p>)}
        </div>
      )}
    </div>);
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    maxHeight: "80%",
  };

  return (
    <Modal
      disablePortal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="word-definition-title"
      aria-describedby="word-definition-description"
    >
      <Box sx={style}>
        <div id="word-definition-title">
          <Typography variant="h4" className='font-bold'>{definition.word}</Typography>
        </div>
        <div id="word-definition-description">
          {definition.classes.map((wc, index) => renderClass(wc, index))}
        </div>
      </Box>
    </Modal>
  );
}
