import { HttpRequest, HttpResult } from "../common/HttpRequest";
import { NewWord } from "../common/models/NewWord";
import { None } from "../common/models/None";
import { WordResponse } from "./WordResponse";
import { WordDefinition } from "../common/models/WordDefinition";

export const WordData = {
  getWords: async (): Promise<HttpResult<WordResponse[]>> => {
    return await HttpRequest.get("words");
  },
  saveNewWord: async (word: NewWord): Promise<HttpResult<None>> => {
    return await HttpRequest.post("words", word);
  },
  getDefinition: async (word: string): Promise<HttpResult<WordDefinition>> => {
    return await HttpRequest.get("words/definition", {
      params: { word: word },
    });
  },
};
