export class HttpError {
  public statusCode: number | "";
  public errorString: string;
  public errorType: NetworkErrorType;

  constructor(data?: any) {
    const dataCloned = data || {};
    this.statusCode = data.statusCode || "";
    this.errorString = data.errorString || "";
    this.errorType = getErrorType(data.statusCode);
  }
}

const getErrorType = (statusCode: any): NetworkErrorType => {
  if (statusCode >= 400 && statusCode < 500) {
    return NetworkErrorType.Client;
  }
  if (statusCode >= 500) {
    return NetworkErrorType.Server;
  }
  return NetworkErrorType.Network;
};

export enum NetworkErrorType {
  Server,
  Client,
  Network,
}
