import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Button,
  Container,
  createTheme,
  CssBaseline,
  Snackbar,
  ThemeProvider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./App.css";
import { NewWord } from "./common/models/NewWord";
import { Word } from "./common/models/Word";
import Loadable from "./components/Loadable";
import NewWordModal from "./components/modals/NewWordModal";
import { Navbar } from "./components/Navbar";
import WordList from "./components/WordList";
import { LoginContext, LoginStateModel } from "./Context/LoginContext";
import { NotiContext, NotiModel, NotiType } from "./Context/NotiContext";
import { WordData } from "./data/WordData";

const darkTheme = createTheme({
  palette: {
    text: {
      disabled: "#BDBDBD",
      primary: "#FAFAFA",
      secondary: "#000000",
    },
    background: {
      default: "#424242",
    },
    mode: "dark",
    primary: {
      light: "#FF7043",
      main: "#F4511E",
      dark: "#D84315",
      contrastText: "#FAFAFA",
    },
    secondary: {
      light: "#7E57C2",
      main: "#5E35B1",
      dark: "#4527A0",
      contrastText: "#FAFAFA",
    },
    error: {
      main: "#D81B60",
      light: "#EC407A",
      dark: "#AD1457",
      contrastText: "#FAFAFA",
    },
    success: {
      main: "#43A047",
      light: "#66BB6A",
      dark: "#2E7D32",
      contrastText: "#FAFAFA",
    },
    warning: {
      main: "#C0CA33",
      light: "#D4E157",
      dark: "#9E9D24",
      contrastText: "#000000",
    },
    info: {
      main: "#00897B",
      light: "#26A69A",
      dark: "#00695C",
      contrastText: "#FAFAFA",
    },
  },
});

function App() {
  const [loginState, setLoginState] = useState<LoginStateModel>({
    isLogin: false,
    userName: "",
    profilePicture: "",
  });

  const [notiState, setNotiState] = useState<NotiModel | undefined>(undefined);
  const [isAppLoading, setIsAppLoading] = React.useState(true);
  const [isLoadingWordList, setLoadingWordList] = React.useState(true);

  const handleLoading = () => {
    setIsAppLoading(false);
  };

  const handleCloseSnackbar = () => {
    setNotiState(undefined);
  };

  const getSeverityEmoji = (notiType: NotiType) => {
    switch (notiType) {
      case NotiType.Info:
        return "😗";
      case NotiType.Alert:
        return "😟";
      case NotiType.Success:
        return "😆";
      case NotiType.Error:
        return "😭";
    }
  };

  const getAlertSeverity = (
    notiType: NotiType | undefined,
  ): "error" | "warning" | "success" | "info" | undefined => {
    if (!notiType) {
      return undefined;
    }

    switch (notiType) {
      case NotiType.Info:
        return "info";
      case NotiType.Alert:
        return "warning";
      case NotiType.Success:
        return "success";
      case NotiType.Error:
        return "error";
    }
  };

  useEffect(() => {
    window.addEventListener("load", handleLoading);
    return () => window.removeEventListener("load", handleLoading);
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     const keycloak = new Keycloak({
  //       url: "http://localhost:8080/",
  //       realm: "shibuicat-realm",
  //       clientId: "shibuicat-universe",
  //     });
  //
  //     try {
  //       const authenticated = await keycloak.init({
  //         onLoad: "login-required",
  //         checkLoginIframe: false,
  //       });
  //       if (authenticated) {
  //         setLoginState({ isLogin: true, userName: "", profilePicture: "" });
  //       }
  //     } catch (error) {
  //       console.error("Failed to initialize adapter:", error);
  //     }
  //   })();
  // }, []);
  //
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoadingWordList(true);
    const fetchWordResult = await WordData.getWords();
    if (fetchWordResult.success) {
      setWords(
        fetchWordResult.data.map((word) => {
          return new Word({ ...word, word: word.innerWord });
        }),
      );
    } else {
      setNotiState({
        type: NotiType.Error,
        content: "Sorry but i don't know what went wrong. We will fix it ASAP!",
      });
    }
    setLoadingWordList(false);
  };

  const showToast = (notiState: NotiModel | undefined): boolean => {
    return notiState && notiState.content && notiState.content.length > 0
      ? true
      : false;
  };

  const saveNewWord = async (word: NewWord) => {
    const saveNewWordResult = await WordData.saveNewWord(word);
    if (saveNewWordResult.success) {
      setNotiState({
        type: NotiType.Success,
        content: `New Word "${word.word}" saved!`,
      });
      await fetchData();
      setNewWordModalOpen(false);
    } else {
      setNotiState({
        type: NotiType.Error,
        content: `Failed to save word "${word.word}". Please try again later `,
      });
    }
  };

  const [words, setWords] = useState<Word[]>([]);
  const [newWordModalOpen, setNewWordModalOpen] = useState(false);

  const snackBar = notiState ? (
    <Snackbar
      open={showToast(notiState)}
      autoHideDuration={5000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert variant="filled" severity={getAlertSeverity(notiState.type)}>
        {notiState.content} {getSeverityEmoji(notiState.type)}
      </Alert>
    </Snackbar>
  ) : null;

  return (
    <Loadable open={isAppLoading} fullScreen={true}>
      <div className="App">
        <NotiContext.Provider
          value={{ state: notiState, setState: setNotiState }}
        >
          <LoginContext.Provider
            value={{ state: loginState, setState: setLoginState }}
          >
            <ThemeProvider theme={darkTheme}>
              <CssBaseline />
              <Navbar />
              <Container sx={{ marginTop: "2rem" }} maxWidth="xl">
                <Button
                  variant="contained"
                  sx={{ marginBottom: "15px" }}
                  onClick={() => setNewWordModalOpen(true)}
                >
                  Add New Word <AddIcon sx={{ verticalAlign: "middle" }} />
                </Button>
                <WordList words={words} loading={isLoadingWordList}></WordList>
                <NewWordModal
                  save={async (data) => await saveNewWord(data)}
                  open={newWordModalOpen}
                  close={() => {
                    setNewWordModalOpen(false);
                  }}
                ></NewWordModal>
              </Container>
              {snackBar}
            </ThemeProvider>
          </LoginContext.Provider>
        </NotiContext.Provider>
      </div>
    </Loadable>
  );
}

export default App;
