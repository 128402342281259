import Loader from "./Loader";

export default function Loadable(props: {
  children: any;
  open: boolean;
  fullScreen: boolean;
  inlay?: boolean;
}) {
  if (props.open) {
    if (!props.inlay) {
      return <Loader fullScreen={props.fullScreen}>{props.children}</Loader>;
    }

    return <Loader fullScreen={props.fullScreen}>{props.children}</Loader>;
  }
  return props.children;
}
