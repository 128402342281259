import React, { createContext } from "react";

export type NotiContextModel = {
  state?: NotiModel;
  setState: React.Dispatch<React.SetStateAction<NotiModel | undefined>>;
};

export type NotiModel = {
  type: NotiType;
  content: string;
};

export enum NotiType {
  Alert = 1,
  Info,
  Error,
  Success,
}

export const NotiContext = createContext<NotiContextModel>({
  state: undefined,
  setState: () => { },
});
