import axios, { AxiosResponse } from "axios";

import { HttpError } from "./HttpError";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const HttpRequest = {
  get: async <T>(path: string, config: any = {}) => {
    return await get<T>(path, config);
  },
  post: async <T, R>(path: string, body: T, config: any = {}) => {
    return await post<T, R>(path, body, config);
  },
};

async function post<T, R>(
  path: string,
  body: T,
  config: any,
): Promise<HttpResult<T>> {
  try {
    const result = await axios.post(`${BACKEND_URL}${path}`, body, config);
    return new HttpResult({
      data: result.data || {},
      error: null,
      success: isSuccessStatusCode(result.status),
    });
  } catch ({ message, error }) {
    return new HttpResult({
      success: false,
      error: new HttpError({ errorString: message }),
    });
  }
}

async function get<T>(path: string, config: any): Promise<HttpResult<T>> {
  try {
    const result = await axios.get(`${BACKEND_URL}${path}`, config);

    return new HttpResult({
      data: result.data,
      error: null,
      success: isSuccessStatusCode(result.status),
    });
  } catch ({ message, code }) {
    return new HttpResult({
      success: false,
      error: new HttpError({ errorString: message }),
    });
  }
}

const getUrl = (path: string) => `${BACKEND_URL}${path}`;

export function isSuccessStatusCode(statusCode: number): boolean {
  return statusCode < 400;
}

export class HttpResult<T> {
  public success: boolean;
  public data: T;
  public error: HttpError;

  constructor(data: any) {
    this.success = data.success;
    this.data = data.data || {};
    this.error = data.error;
  }
}
