import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Fragment, useContext, useEffect, useState } from "react";
import { dateTimeReadableFormat } from "../common/helpers/datetime";
import { LoaderProp } from "../common/models/LoaderProp";
import { Word } from "../common/models/Word";
import { WordDefinition } from "../common/models/WordDefinition";
import { NotiContext, NotiType } from "../Context/NotiContext";
import { WordData } from "../data/WordData";
import Loadable from "./Loadable";
import WordDefinitionModal from "./modals/WordDefinitionModal";
import "./WordList.css";

type WordListProps = {
	words: Word[];
} & LoaderProp;

type ViewWordState = {
	word: string;
	mode: string;
};

export default function WordList(props: WordListProps) {
	let [viewWordDefinition, setViewWordDefinition] = useState<ViewWordState>({
		word: "",
		mode: "en",
	});

	let noti = useContext(NotiContext);

	const closeWordDefinitionModal = () => {
		setViewWordDefinition({
			word: "",
			mode: "en",
		});
	};

	const [currentWordDefinition, setCurrentWordDefinition] =
		useState<WordDefinition>();

	const [fetchingDefinition, setFetchingDefinition] = useState(false);

	const viewEngDefinition = (word: string) => {
		setViewWordDefinition({ word: word, mode: "en" });
	};

	useEffect(() => {
		const fetchWordDefinition = async (word: string) => {
			setFetchingDefinition(true);
			const result = await WordData.getDefinition(word);
			setFetchingDefinition(false);
			console.log(fetchingDefinition);
			if (result.success && result.data != null) {
				if (result.data.classes && result.data.classes.length > 0) {
					setCurrentWordDefinition(result.data);
				} else {
					noti.setState({
						content: `The word ${word} doesn't have a definition`,
						type: NotiType.Info,
					});
				}
			} else {
				noti.setState({
					content: `Failed to fetch definition for word ${word}! Please try again later`,
					type: NotiType.Error,
				});
			}
		};

		if (viewWordDefinition.word) {
			fetchWordDefinition(viewWordDefinition.word);
		}
	}, [viewWordDefinition]);

	return (
		<Loadable open={props.loading || fetchingDefinition} fullScreen={false}>
			<Fragment>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Word</TableCell>
								<TableCell>Context</TableCell>
								<TableCell>Additional Context</TableCell>
								<TableCell>Created At (UTC)</TableCell>
								<TableCell>Definition</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.words.map((row: Word) => (
								<TableRow
									key={row.word}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
								>
									<TableCell component="td" scope="row">
										<Typography>{row.word}</Typography>
									</TableCell>
									<TableCell component="td" scope="row">
										<Typography>{row.context}</Typography>
									</TableCell>
									<TableCell component="td" scope="row">
										<Typography>{row.additionalContext}</Typography>
									</TableCell>
									<TableCell component="td" scope="row">
										<Typography>
											{dateTimeReadableFormat(row.createdAtUtc)}
										</Typography>
									</TableCell>
									<TableCell component="td" scope="row">
										<Button
											onClick={() => viewEngDefinition(row.word)}
											variant="text"
										>
											Eng
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				{currentWordDefinition ? (
					<WordDefinitionModal
						wordDefinition={currentWordDefinition}
						open={
							currentWordDefinition && viewWordDefinition.word ? true : false
						}
						handleClose={closeWordDefinitionModal}
					/>
				) : null}
			</Fragment>
		</Loadable>
	);
}
